<template>
  <v-list dense>
    <draggable
      v-model="fields"
      @end="endDrag"
    >
      <v-list-item
        v-for="item in fields"
        :key="item.$id"
        dense
      >
        <v-list-item-avatar>
          <v-icon
            class="row-grabbing"
            dense
            @click="()=>null"
          >
            mdi-equal
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ getItemText(item) }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </draggable>
  </v-list>
</template>

<script>
import modelMixin from '@/mixins/modelMixin'
import draggable from 'vuedraggable'

export default {
  name: 'RankList',
  components: { draggable },
  mixins: [modelMixin],
  props: {
    itemText: {
      type: String,
      required: true
    }
  },
  methods: {
    getItemText (item) {
      const keys = this.itemText.split('.')
      let cachedItem = item
      keys.forEach(key => {
        cachedItem = cachedItem ? cachedItem[key] : null
      })
      return cachedItem
    },
    endDrag () {
      this.fields.forEach((instance, index) => {
        instance.rank = index
      })
      this.$emit('input', this.fields)
    }
  }

}
</script>

<style scoped>
.row-grabbing:hover {
  cursor: move;
}
</style>
