<template>
  <v-card flat>
    <v-form v-model="form">
      <v-toolbar
        class="mt-2"
        dense
        flat
      >
        <v-btn
          icon
          @click="$router.go(-1)"
        >
          <v-icon dense>
            mdi-arrow-left
          </v-icon>
        </v-btn>
        <v-breadcrumbs :items="breadcrumbs" />
        <v-spacer />
        <slot
          name="actions"
          :form="form"
          :fields="fields"
          :isEqual="isEqual"
        />
      </v-toolbar>

      <v-card-text>
        <v-text-field
          v-model="fields.name"
          :rules="[rules.required]"
          label="Name"
          filled
          outlined
          dense
        />
        <v-switch
          v-model="fields.isActive"
          label="Use this menu ? (You need one active menu)"
          required
          dense
        />
      </v-card-text>
      <v-card-text>
        <ManyToManySelector
          v-model="fields.menuCategories"
          :company-id="fields.companyId"
          :establishment-id="fields.establishmentId"
          label="Categories"
          :items="categories.all()"
          item-text="name"
          item-value="id"
          relation="category"
          multiple
        />
      </v-card-text>
      <v-card-title>Categories</v-card-title>
      <v-card-text>
        <RankList
          v-model="fields.menuCategories"
          item-text="category.name"
        />
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>

import { mapGetters } from 'vuex'
import ManyToManySelector from '@/components/ManyToManySelector'
import RankList from '@/components/RankList'
import formMixin from '@/mixins/formMixin'

export default {
  name: 'MenuForm',
  components: { ManyToManySelector, RankList },
  mixins: [formMixin],
  props: {
    breadcrumbs: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters('app', ['categories'])
  }
}
</script>
