<template>
  <MenuForm
    v-if="instance"
    :value="instance"
    :breadcrumbs="breadcrumbs"
  >
    <template #actions="{form, fields, isEqual}">
      <VButtonDialog @confirm="remove(fields)">
        Delete
      </VButtonDialog>
      <v-btn
        :disabled="!form || isEqual"
        color="success"
        class="mx-2"
        rounded
        @click="update(fields)"
      >
        Save
      </v-btn>
    </template>
  </MenuForm>
</template>

<script>
import MenuForm from '@/pages/App/MenuCreator/Menus/__MenuForm__'
import { Menu } from '@/store/models'
import VButtonDialog from '@/components/VButtonDialog'
import removeMixin from '@/mixins/apiRemoveMixin'
import updateMixin from '@/mixins/apiUpdateMixin'
import { mapGetters } from 'vuex'

export default {
  name: 'MenuId',
  components: { MenuForm, VButtonDialog },
  mixins: [removeMixin(Menu), updateMixin(Menu)],
  computed: {
    ...mapGetters('app', ['menus']),
    breadcrumbs () {
      return [
        {
          text: 'Menus',
          exact: true,
          to: { name: 'App-MenuCreator-Menus' }
        },
        {
          text: this.instance.name,
          exact: true,
          to: { name: 'App-MenuCreator-Menus-id', params: { id: this.instance.id } }
        }
      ]
    },
    instance () {
      const menu = this.menus.find(this.$route.params.id)
      if (menu?.menuCategories) {
        menu.menuCategories.sort((x, y) => x.rank - y.rank)
      }
      return menu
    }
  }
}
</script>
